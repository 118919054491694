export const accountConstants = {
    GET_ACCOUNT_REQUEST: 'GET_ACCOUNT_REQUEST',
    GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',
    GET_ACCOUNT_FAILURE: 'GET_ACCOUNT_FAILURE',

    ADD_ACCOUNT_REQUEST: 'ADD_ACCOUNT_REQUEST',
    ADD_ACCOUNT_SUCCESS: 'ADD_ACCOUNT_SUCCESS',
    ADD_ACCOUNT_FAILURE: 'ADD_ACCOUNT_FAILURE',

    UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
    UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
    UPDATE_ACCOUNT_FAILURE: 'UPDATE_ACCOUNT_FAILURE'    
};