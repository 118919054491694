import React, { Component } from 'react';
class Landing extends Component {
    render() {
        return (
            <div className="service">
                Integration Success!! 😃
                <p>Refresh the page to view the OrderGroove integration details</p>
            </div>
        );
    }
}

export default Landing;