import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container, Row, Col
} from 'reactstrap';
import { jobScheduleActions } from './../../actions';
import cronstrue from 'cronstrue';
import { utility } from './../../helpers';

class CronSettingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            JobScheduleId: '',
            Minute: '*',
            Hour: '*',
            Day: '*',
            Month: '*',
            Weekday: '*'
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitJobSchedule = this.submitJobSchedule.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.jobScheduleUpdate !== this.props.jobScheduleUpdate) {
            if (!utility.isObjectEmpty(nextProps.jobScheduleUpdate)
                && nextProps.jobScheduleUpdate !== undefined
                && nextProps.jobScheduleUpdate.message === 'Schedule Job updated successfully'
            ) {
                this.props.dispatch(jobScheduleActions.getAllJobSchedule());
                this.setState({ modal: false });   
            }
        }
        if (nextProps.jobScheduleCreate !== this.props.jobScheduleCreate) {
            if (!utility.isObjectEmpty(nextProps.jobScheduleCreate)
                && nextProps.jobScheduleCreate !== undefined
                && nextProps.jobScheduleCreate.message === 'Schedule Job created successfully'
            ) {
                this.props.dispatch(jobScheduleActions.getAllJobSchedule());
                this.setState({ modal: false });   
            }
        }        
    }
    toggle(e) {
        e.preventDefault();
        const { schedule } = this.props;
        if (schedule !== undefined && this.state.modal === false) {
            this.setState({
                JobScheduleId: schedule.ScheduleJobId, 
                Minute: schedule.Minute,
                Hour: schedule.Hour,
                Day: schedule.Day,
                Month: schedule.Month,
                Weekday: schedule.Weekday,
            }, () => {
                this.setState({ modal: true });
            });
        } else {           
            this.setState(prevState => ({
                modal: !prevState.modal
            }));
        }
    }
    renderSelectOptions(type, start, count) {
        const monthNameList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const weekDays = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday'
        ];
        let items = [];
        items.push(<option key={utility.guid()} value='*'>*</option>);
        if (type === 'day_week') {
            let i = 1;
            weekDays.map((o) => {
                items.push(<option key={utility.guid()} value={i}>{o}</option>);
                i = i + 1;
            });
        } else if (type === 'month') {
            let i = 1;
            monthNameList.map((o) => {
                items.push(<option key={utility.guid()} value={i}>{o}</option>);
                i = i + 1;
            });
        } else{
            for (let i = start; i <= count; i++) {
                items.push(<option key={utility.guid()} value={i}>{i}</option>);
                if (i !== 0) {
                    const every = '*/' + i;
                    items.push(<option key={utility.guid()} value={every}>{every}</option>);
                }             
            }
        }  
        return items;
    }
    handleChange(e) {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }     
    submitJobSchedule(e) {
        e.preventDefault();
        const data = {
            JobName: this.props.scheduleName,
            Minute: this.state.Minute,
            Hour: this.state.Hour,
            Day: this.state.Day,
            Month: this.state.Month,
            Weekday: this.state.Weekday
        }
        if (this.state.JobScheduleId) {
            this.props.dispatch(jobScheduleActions.updateJobSchedule(this.state.JobScheduleId, data));
        } else {
            this.props.dispatch(jobScheduleActions.createJobSchedule(data));
        }
    }
    renderScheduleConfig() {
        return (
            <React.Fragment >
                <Row>
                    <Col sm='6'>
                        <div className="form-group">
                            <label>Minute</label>
                            <Input type="select" name="Minute" value={this.state.Minute} onChange={this.handleChange}>
                                {this.renderSelectOptions('minute', 0, 59)}
                            </Input>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className="form-group">
                            <label>Hour</label>
                            <Input type="select" name="Hour" value={this.state.Hour} onChange={this.handleChange}>
                                {this.renderSelectOptions('hour', 0, 23)}
                            </Input>  
                        </div>
                    </Col>
                </Row >
                <Row>
                    <Col sm='6'>
                        <div className="form-group">
                            <label>Day(Month)</label>
                            <Input type="select" name="Day" value={this.state.Day} onChange={this.handleChange}>
                                {this.renderSelectOptions('day_month', 1, 31)}
                            </Input>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className="form-group">
                            <label>Month</label>                    
                            <Input type="select" name="Month" value={this.state.Month} onChange={this.handleChange}>
                                {this.renderSelectOptions('month', 1, 12)}
                            </Input> 
                        </div>
                    </Col>
                </Row >
                <Row>
                    <Col sm='6'>
                        <div className="form-group">
                            <label>Day(Week)</label>
                            <Input type="select" name="Weekday" value={this.state.Weekday} onChange={this.handleChange}>
                                {this.renderSelectOptions('day_week', 1, 7)}
                            </Input> 
                        </div>
                    </Col>
                </Row>            
            </React.Fragment >
        );
    }
    renderScheduleTimeInReadableFormat() {
        let scheduleText = 'Not Configured';
        if (this.state.JobScheduleId !== '') {
            const scheduleTime =  this.state.Minute +' '+ this.state.Hour +' '+ this.state.Day +' '+ this.state.Month +' '+ this.state.Weekday;
            scheduleText = cronstrue.toString(scheduleTime);
        }
        return (
            <span>
                {scheduleText}
            </span>
        );
    }
    render() {
        return (
            <div>
                <button className="og-show-detail-btn" onClick={this.toggle}>Schedule Job</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className="cron-modal">
                    <ModalHeader toggle={this.toggle}>Configure Schedule Time</ModalHeader>
                    <ModalBody>
                        <h4 className="scheduled-time">
                            {this.renderScheduleTimeInReadableFormat()}
                        </h4>
                        {this.renderScheduleConfig()}
                        {
                            (this.props.jobScheduleUpdateError !== null || this.props.jobScheduleCreateError !== null) ?
                                <div>Failed to save configuration</div>
                                :
                                null
                        }                   
                        {
                            (this.props.jobScheduleUpdateRequestLoading === true
                            || this.props.jobScheduleCreateRequestLoading === true
                            ) ?
                                <p> Please wait, saving job schedule configuration  </p>
                                :
                                <div className="btn-wrapper">                                
                                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>{' '}
                                    <Button color="primary" onClick={this.submitJobSchedule}>Save</Button>
                                </div>
                        }
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { 
        jobScheduleUpdate,
        jobScheduleUpdateRequestLoading,
        jobScheduleUpdateError,
        jobScheduleCreate,
        jobScheduleCreateRequestLoading,
        jobScheduleCreateError        
    } = state.jobScheduleReducer;

    return (
        {
            jobScheduleUpdate,
            jobScheduleUpdateRequestLoading,
            jobScheduleUpdateError,
            jobScheduleCreate,
            jobScheduleCreateRequestLoading,
            jobScheduleCreateError                        
        }
    );
};
export default connect(mapStateToProps)(CronSettingModal);
