import { API } from '../helpers';

function addAccount(formData) {
    const url = '/api/v1/app/settings';
    return API.ENDPOINT.post(url, formData)
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
};

function getAccount() {
    return API.ENDPOINT.get('/api/v1/app/settings')
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
};

function updateAccount(formData) {
    const url = '/api/v1/app/settings';
    return API.ENDPOINT.put(url, formData)
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
};

export const accountService = {
    addAccount,
    getAccount,
    updateAccount,
};