import axios from 'axios';

const baseUrl = process.env.URL;

const ENDPOINT = axios.create({
    baseURL: baseUrl,
    timeout: 9999999,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/octet-stream'
    }
});

ENDPOINT.interceptors.request.use(
    (config) => {
        config.headers['signed-payload'] = localStorage.getItem('signed-payload');
        config.headers['storehash'] = localStorage.getItem('storehash');
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers.Expires = '-1';
        config.headers['Cache-Control'] = 'no-cache,no-store,must-revalidate,max-age=-1,private';
        config.headers.Pragma = 'no-cache';

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

ENDPOINT.interceptors.response.use(
    (response) => {
        if(response.hasOwnProperty('data')){
            if(response['data'].hasOwnProperty('data')){
                if(response['data']['data'].hasOwnProperty('hideExportImport')){
                    var els = document.querySelectorAll("a[href='/export-import']");
                            els[0].style.display = "none";
                } 
            }
        }
        return response; 
    },
    (error) => {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }
        if (error.response.status === 401) {
            var reloadUrl = document.referrer;
            if(document.referrer.indexOf('/manage/app') == -1 ){
                reloadUrl = document.location.href;
            }
            window.parent.location = reloadUrl;
            // window.location.href = '/not-authorized';
        }
    }
);

const PURE_XML_HTTP = {
    post: (url) => {
        const xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open('POST', ENV.API_LEGACY_BASE_URL + url, true);
        xmlHttpRequest.setRequestHeader('Content-Type', 'application/json');
        xmlHttpRequest.setRequestHeader('signed-payload', localStorage.getItem('signed-payload'));
        xmlHttpRequest.setRequestHeader('storehash', localStorage.getItem('storehash'));
        xmlHttpRequest.responseType = 'blob';
        xmlHttpRequest.timeout = '99999999';
        return xmlHttpRequest;
    },    
    get: (url) => {
        const xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.open('GET', ENV.API_BASE_URL + url, true);
        xmlHttpRequest.responseType = 'blob';
        xmlHttpRequest.timeout = '99999999';
        return xmlHttpRequest;
    }
};

export const API = {
    ENDPOINT,
    PURE_XML_HTTP,
};
