import _ from 'lodash';
import moment from 'moment';

const inArray = (param, value) => {
    const found = _.find(param, (item) => { return item === value; });
    if (found) {
        return true;
    }
    return false;
};
const guid = () => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};
const isObjectEmpty = (param) => {
    return _.isEmpty(param);
}
const isValueObject = (param) => {
    if (param === null) {
        return false;
    }
    return ( (typeof param === 'function') || (typeof param === 'object'));    
}
const roundToTwoDecimail = (param) => {
    if (param === null || param === 0 || param === undefined) {
        return 0;
    }
    return parseFloat(Math.round(param * 100) / 100).toFixed(2);
}
const getDisplayDate = (param) => {
    if (param === null || param === 0 || param === undefined) {
        return null;
    }
    return moment(param).format('MM-DD-YYYY hh:mm:ss');
}
const getDateSqlFormat = (param) => {
    if (param === null || param === 0 || param === undefined) {
        return null;
    }
    return moment(param).format('YYYY-MM-DD');
}
const getDisplayDateFromTimestamp = (param) => {
    if (param === null || param === 0 || param === undefined) {
        return null;
    }
    return  moment.unix(param).format('MM-DD-YYYY hh:mm:ss');
}
export const utility = {
    guid,
    inArray,
    isObjectEmpty,
    isValueObject,
    roundToTwoDecimail,
    getDisplayDate,
    getDateSqlFormat,
    getDisplayDateFromTimestamp
};
