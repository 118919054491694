import { analyticsConstants } from '../constants';
import { analyticService } from '../services';

const getStats = (
    fromDate,
    toDate
) => {
    const success = (stats) => {
        return {
            type: analyticsConstants.STATS_SUCCESS,
            payload: stats
        };
    };

    const failure = ({ error }) => {
        return {
            type: analyticsConstants.STATS_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: analyticsConstants.STATS_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());

        analyticService.getStats(
            fromDate,
            toDate
        )
        .then(
            (stats) => {
                dispatch(success(stats.data.data));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };
};
const getStatsDetail  = (
    type,
    fromDate,
    toDate
) => {
    const success = (statsDetail) => {
        return {
            type: analyticsConstants.STATS_DETAIL_SUCCESS,
            payload: statsDetail
        };
    };

    const failure = ({ error }) => {
        return {
            type: analyticsConstants.STATS_DETAIL_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: analyticsConstants.STATS_DETAIL_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());

        analyticService.getStatsDetail(
            type,
            fromDate,
            toDate
        )
            .then(
                (statsDetail) => {
                    dispatch(success(statsDetail.data.data));
                },
                (error) => {
                    dispatch(failure(error));
                }
            );
    };
};
export const analyticsActions = {
    getStats,
    getStatsDetail
};