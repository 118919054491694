import { API } from '../helpers';

function exportProducts() {
    return API.ENDPOINT.get('/api/v1/export/trigger')
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}

function getProductExportStatus() {
    return API.ENDPOINT.get('/api/v1/export/status')
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}

function downloadProductsCsv() {
    return API.ENDPOINT.get('/api/v1/export/download', { responseType: 'blob'})
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}

function getProductImportStatus() {
    return API.ENDPOINT.get('/api/v1/import/status')
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}

export const exportImportService = {
    exportProducts,
    getProductExportStatus,
    downloadProductsCsv,
    getProductImportStatus
};