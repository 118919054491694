import React, { Component } from 'react';
import _ from 'lodash';
import { utility } from './../../helpers';

class StatsTable extends Component {
    constructor(props) {
        super(props);
    }        
    renderStatsTable(data) {
        return data.map((row) => {
            return (
                <tr key={row.RecordId}>
                    <td>
                        {utility.getDisplayDate(row.CreatedAt)}
                    </td>
                    <td>
                        {
                            (row.Status === '0' || row.Status === 0) ? 'Failed' : 'Success'
                        }
                    </td>
                    <td>{row.Request}</td>
                    <td>{row.Response}</td>
                </tr>    
            );
        });
    }
    renderTableHeader() {
        return(
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Request</th>
                    <th>Response</th>
                </tr>
            </thead>
        );
    }
    render() {
        const { statsDetail } = this.props;
        const stats = _.filter(this.props.statsBlock, { 'type': this.props.tableType });
        let heading = '';
        if (stats[0] !== undefined) {
            heading = stats[0].value.name;
        }
        return(
            <section className="checkout-section">
                <p className="checkouts">{heading}</p>
                <table className="stats-table">
                    {this.renderTableHeader()}
                    <tbody>
                    {
                        (utility.isObjectEmpty(statsDetail)) ?
                            (this.props.statsDetailRequestLoading !== true) ?
                                <tr><td colSpan={6}>No details found</td></tr>
                                :
                                null
                            :
                            this.renderStatsTable(statsDetail)       
                    }
                    {
                        (this.props.statsDetailRequestLoading) ?
                            <tr><td colSpan={6}>Loading...</td></tr>
                            :
                            null
                    }
                    </tbody>
                </table>
            </section>            
        );
    }
}
export default StatsTable;