import React, { Component } from 'react';
import {
    Route,
    Link,
    Redirect,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';
import LayoutRoute from './layout_router';
import ExportImport from './pages/export_import_page';
import Analytics from './pages/analytics_page';
import Account from './pages/account_page';
import NotFound from './pages/not_found_page';
import Landing from './pages/landing_page';
import NotAuthorized from './pages/not_authorized_page';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <LayoutRoute path='/app/load/:appId/bigcommerce' component={Analytics} />
                    <LayoutRoute path='/analytics' component={Analytics} />
                    <LayoutRoute path='/landing' component={Landing} />
                    <LayoutRoute path='/export-import' component={ExportImport} />
                    <LayoutRoute path='/app/install-complete/:storehash/:appId' component={Account} />
                    <LayoutRoute path='/account' component={Account} />
                    <LayoutRoute path='/not-authorized' component={NotAuthorized} />
                    <LayoutRoute component={NotFound} />
                </Switch>
            </Router>
        );
    }  
}

export default App;
