import React, { Component } from 'react';
import Import from './../components/file/import_file';
import Export from './../components/file/export_file';

class ExportImport extends Component {
    render() {
        return (
            <div className="section-wrapper">
                <div className="file-wrapper">
                    <Import />
                    <Export />
                </div>
			</div>
        );
    }
}

export default ExportImport;