import { API } from '../helpers';

function productFeedSync() {
    return API.ENDPOINT.get('/api/v1/sync/product')
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}

function productCategoryFeedSync() {
    return API.ENDPOINT.get('/api/v1/sync/product-category')
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}

export const feedSyncService = {
    productFeedSync,
    productCategoryFeedSync
};