export const jobScheduleConstants = {

    JOB_SCHEDULE_REQUEST: 'JOB_SCHEDULE_REQUEST',
    JOB_SCHEDULE_SUCCESS: 'JOB_SCHEDULE_SUCCESS',
    JOB_SCHEDULE_FAILURE: 'JOB_SCHEDULE_FAILURE',

    JOB_SCHEDULE_CREATE_REQUEST: 'JOB_SCHEDULE_CREATE_REQUEST',
    JOB_SCHEDULE_CREATE_SUCCESS: 'JOB_SCHEDULE_CREATE_SUCCESS',
    JOB_SCHEDULE_CREATE_FAILURE: 'JOB_SCHEDULE_CREATE_FAILURE',

    JOB_SCHEDULE_UPDATE_REQUEST: 'JOB_SCHEDULE_UPDATE_REQUEST',
    JOB_SCHEDULE_UPDATE_SUCCESS: 'JOB_SCHEDULE_UPDATE_SUCCESS',
    JOB_SCHEDULE_UPDATE_FAILURE: 'JOB_SCHEDULE_UPDATE_FAILURE'
};