import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

class AlertMessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.isOpen
        };

        this.toggle = this.toggle.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            modal: nextProps.isOpen
        });
    }
    toggle() {
        this.setState({
            modal: !this.state.modal
        }, () => {
            this.props.closeAlertMessage();
        });
    }    
    render() {
        let { heading, content } = this.props;
        if (heading === undefined) {
            heading = "Information";
        }
        if (content === undefined) {
            content = '';
        }
        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                >
                    <ModalHeader toggle={this.toggle}>
                        {heading}
                    </ModalHeader>
                <ModalBody>
                    {content}
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={this.toggle}>Ok</Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default AlertMessageModal;
