import { API } from '../helpers';

/**
 * getStats
 * @param {*} fromDate 
 * @param {*} toDate 
 */
function getStats(fromDate, toDate) {
    const url = `/api/v1/analytics/summary?from_date=${fromDate}&to_date=${toDate}`;
    return API.ENDPOINT.get(url)
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}

/**
 * getStatsDetail
 * @param {*} type 
 * @param {*} fromDate 
 * @param {*} toDate 
 */
function getStatsDetail(type, fromDate, toDate) {
    let url = '/api/v1/analytics';
    let statsType = 'checkout';
    switch(type) {
        case 'checkout':
            statsType = 'checkout';
            break;
        case 'subscription':
            statsType = 'subscription';
            break;
        case 'customer':
            statsType = 'customer-update';
            break;
        case 'product':
            statsType = 'product-sync';
            break;
        case 'category':
            statsType = 'category-sync';
            break;
        default:
            statsType = 'checkout';
    }
    url = `${url}/${statsType}/details?from_date=${fromDate}&to_date=${toDate}`;
    return API.ENDPOINT.get(url)
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}
export const analyticService = {
    getStats,
    getStatsDetail
};