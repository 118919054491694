import { API } from '../helpers';

function getAllJobSchedule() {
    return API.ENDPOINT.get('api/v1/schedule/job')
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}
function createJobSchedule(data) {
    return API.ENDPOINT.post('/api/v1/schedule/job', data)
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}
function updateJobSchedule(id, data) {
    const url = `/api/v1/schedule/job/${id}`;
    return API.ENDPOINT.put(url, data)
        .then((response) => { return response; })
        .catch((error) => { return Promise.reject(error); });
}
export const jobScheduleService = {
    getAllJobSchedule,
    createJobSchedule,
    updateJobSchedule
};