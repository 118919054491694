import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import {
    analyticsActions,
    jobScheduleActions,
    feedSyncActions
} from './../actions';
import Stats from './../components/stats/stats';
import DateSearch from './../components/search/date_search';
import StatsTable from './../components/table/stats_table';
import AlertMessageModal from './../components/modal/alert_message_modal';
import Loader from './../components/loader/loader';
import { utility } from './../helpers';

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            statsType: '',
            fromDate: new Date(),
            toDate: new Date(),
            syncResponse: false,
            syncResponseMessage: ''
        };
        this.statsBlock = [
            { 'type': 'checkout', 'value': {'name': 'Subscription Order Processed', 'sync': 0}},
            { 'type': 'subscription', 'value': {'name': 'Checkouts Processed', 'sync': 0}},
            { 'type': 'customer', 'value': {'name': 'Customer Updates Processed', 'sync': 0}},
            { 'type': 'category', 'value': {'name': 'Product Category Feed', 'schedule_name' : 'PRODUCT_CATEGORY_FEED_SYNC', 'sync': 1}},
            { 'type': 'product', 'value': {'name': 'Product Feed', 'schedule_name' : 'PRODUCT_FEED_SYNC', 'sync': 1}}
        ];        
        this.updateStats = this.updateStats.bind(this);
        this.getStatsDetails = this.getStatsDetails.bind(this);
        this.syncFeed = this.syncFeed.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(
            analyticsActions.getStats(
                moment().format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            )
        );
        this.props.dispatch(jobScheduleActions.getAllJobSchedule());
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.syncProduct !== this.props.syncProduct) {
            if (nextProps.syncProduct !== undefined
                && nextProps.syncProduct.message !== ''
            ) {
                this.setState({
                    syncResponse: true,
                    syncResponseMessage: nextProps.syncProduct.message
                });   
            }
        }
        if (nextProps.syncProductCategory !== this.props.syncProductCategory) {
            if (nextProps.syncProductCategory !== undefined
                && nextProps.syncProductCategory.message !== ''
            ) {
                this.setState({
                    syncResponse: true,
                    syncResponseMessage: nextProps.syncProductCategory.message
                });                
            }
        }        
    }    
    updateStats(fromDate, toDate) {
        this.setState({
            fromDate,
            toDate
        });
        fromDate = utility.getDateSqlFormat(fromDate);
        toDate = utility.getDateSqlFormat(toDate);
        this.props.dispatch(analyticsActions.getStats(fromDate, toDate));
        if (this.state.statsType !== '') {
            this.props.dispatch(analyticsActions.getStatsDetail(
                this.state.statsType, fromDate, toDate
            ));
        }
    }
    getStatsDetails(statsType) {
        this.setState({
            statsType
        });
        const fromDate = utility.getDateSqlFormat(this.state.fromDate);
        const toDate = utility.getDateSqlFormat(this.state.toDate);
        this.props.dispatch(analyticsActions.getStatsDetail(
            statsType, fromDate, toDate
        ));
    }
    syncFeed(type) {
        if (type === 'product') {
            this.props.dispatch(feedSyncActions.syncProductFeed());
        } else {
            this.props.dispatch(feedSyncActions.syncProductCategoryFeed());
        }
    }
    closeAlertMessage = () => {
        this.setState({
            syncResponse: false
        });
    };   
    buildStatus(stats) {
        if (utility.isValueObject(stats) && utility.isObjectEmpty(stats)) {
            return [];
        }
        const response = [];
        this.statsBlock.forEach((block, index) => {
            const { type, value } = block;
            const statsValue = stats[type];
            const total = statsValue.success + statsValue.failure;
            const successPercentage = (total) ? (statsValue.success / total) * 100 : 0;
            const failurePercentage = (total) ? (statsValue.failure / total) * 100 : 0;
            const lastModified = (statsValue.last_modified !== undefined) ? statsValue.last_modified : null;
            const statsOutput = {
                'schedule_name': value.schedule_name,
                'type': type,
                'name': value.name,
                'sync': value.sync,
                'total': total,
                'success' : {
                    'count': statsValue.success, 'percentage': successPercentage
                },
                'error': {
                    'count': statsValue.failure, 'percentage': failurePercentage
                },
                'last_modified': lastModified
            };
            response.push(statsOutput);
        });

        return response;
    }
    render() {
        if (this.props.statsLoading) {
            return (
                <div><Loader /></div>
            );
        }
        const statsData = this.buildStatus(this.props.stats);
        return (
            <div className="section-wrapper">
                <DateSearch
                    handleUpdateDate={this.updateStats}
                />
                {
                    (this.props.statsEror === false) ?
                        <div>Cannot Load Stats. Please try Later.</div>
                        :
                        <div>
                            <Stats
                                jobSchedule={this.props.jobSchedule}
                                statsData={statsData}
                                getStatsDetails={this.getStatsDetails}
                                handleClickSync={this.syncFeed}
                            />
                            <StatsTable 
                                tableType={this.state.statsType}
                                statsBlock={this.statsBlock}
                                statsDetail={this.props.statsDetail}
                                statsDetailRequestLoading={this.props.statsDetailRequestLoading}
                            />
                        </div>
                }
                <AlertMessageModal
                    isOpen={this.state.syncResponse}
                    content={this.state.syncResponseMessage}
                    closeAlertMessage={this.closeAlertMessage}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { 
        jobSchedule,
        jobScheduleRequestLoading,
        jobScheduleRequestError,
    } = state.jobScheduleReducer;

    const {
        stats,
        statsLoading,
        statsEror,
        statsDetail,
        statsDetailRequestLoading,
        statsDetailRequestError
    } = state.analyticsReducer;

    const {
        syncProduct,
        syncProductLoading,
        syncProductError,
        syncProductCategory,
        syncProductCategoryLoading,
        syncProductCategoryError
    } = state.feedSyncReducers;
    return (
        {
            stats,
            statsLoading,
            statsEror,
            statsDetail,
            statsDetailRequestLoading,
            statsDetailRequestError,
            jobSchedule,
            jobScheduleRequestLoading,
            jobScheduleRequestError,
            syncProduct,
            syncProductLoading,
            syncProductError,
            syncProductCategory,
            syncProductCategoryLoading,
            syncProductCategoryError                                    
        }
    );
};

export default connect(mapStateToProps)(Analytics);
