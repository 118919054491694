import { exportImportConstants } from '../constants';

const initialState = {
    productExport: {},
    productExportRequestLoading: false,
    productExportError: null,
    productExportStatus: {},
    productExportStatusRequestLoading: false,
    productExportStatusError: null,
    productDownload: {},
    productDownloadRequestLoading: false,
    productDownloadStatusError: null,
    productImportStatus: {},
    productImportStatusRequestLoading: false,
    productImportStatusError: null
};

export function exportImportReducers(state = initialState, action) {
    switch (action.type) {
        case exportImportConstants.EXPORT_PRODUCT_REQUEST:
            return {
                ...state,
                productExportRequestLoading: true,
                productExportError: null,
                productExport: {}
            };
        case exportImportConstants.EXPORT_PRODUCT_SUCCESS:
            return {
                ...state,
                productExportRequestLoading: false,
                productExportError: null,
                productExport: action.payload
            };
        case exportImportConstants.EXPORT_PRODUCT_FAILURE:
            return {
                ...state,
                productExportRequestLoading: false,
                productExportError: 'Failed to export products',
                productExport: {}
            };
        case exportImportConstants.EXPORT_PRODUCT_STATUS_REQUEST:
            return {
                ...state,
                productExportStatusRequestLoading: true,
                productExportError: null,
                productExportStatus: {}
            };
        case exportImportConstants.EXPORT_PRODUCT_STATUS_SUCCESS:
            return {
                ...state,
                productExportStatusRequestLoading: false,
                productExportStatusError: null,
                productExportStatus: action.payload
            };
        case exportImportConstants.EXPORT_PRODUCT_STATUS_FAILURE:
            return {
                ...state,
                productExportStatusRequestLoading: false,
                productExportStatusError: 'Failed to get export status',
                productExportStatus: {}
            };
        case exportImportConstants.EXPORT_PRODUCT_DOWNLOAD_REQUEST:
            return {
                ...state,
                productDownloadRequestLoading: true,
                productDownloadStatusError: null,
                productDownload: {}
            };
        case exportImportConstants.EXPORT_PRODUCT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                productDownloadRequestLoading: false,
                productDownloadStatusError: null,
                productDownload: action.payload
            };
        case exportImportConstants.EXPORT_PRODUCT_DOWNLOAD_FAILURE:
            return {
                ...state,
                productDownloadRequestLoading: false,
                productDownloadStatusError: 'Failed to get export status',
                productDownload: {}
            };
        case exportImportConstants.IMPORT_PRODUCT_STATUS_REQUEST:
            return {
                ...state,
                productImportStatusRequestLoading: true,
                productImportStatusError: null,
                productImportStatus: {}
            };
        case exportImportConstants.IMPORT_PRODUCT_STATUS_SUCCESS:
            return {
                ...state,
                productImportStatusRequestLoading: false,
                productImportStatusError: null,
                productImportStatus: action.payload
            };
        case exportImportConstants.IMPORT_PRODUCT_STATUS_FAILURE:
            return {
                ...state,
                productImportStatusRequestLoading: false,
                productImportStatusError: 'Failed to get import status',
                productImportStatus: {}
            };                                
        default:
            return state;
    }
}