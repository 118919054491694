import { accountConstants } from '../constants';
import { accountService } from '../services';

const addAccount = (
    formData
) => {
    const success = (account) => {
        return {
            type: accountConstants.ADD_ACCOUNT_SUCCESS,
            payload: account
        };
    };

    const failure = ({error}) => {
        return {
            type: accountConstants.ADD_ACCOUNT_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: accountConstants.ADD_ACCOUNT_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());

        accountService.addAccount(
            formData
        )
        .then(
            (account) => {
                dispatch(success(account.data));
            },
            (error) => {
                dispatch(failure(error));

            }
        );
    };
};

const getAccount = () => {
    const success = (data) => {
        return {
            type: accountConstants.GET_ACCOUNT_SUCCESS,
            payload: data
        };
    };

    const failure = ({error}) => {
        return {
            type: accountConstants.GET_ACCOUNT_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: accountConstants.GET_ACCOUNT_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());

        accountService.getAccount()
        .then(
            (data) => {
                dispatch(success(data.data.data));
            },
            (error) => {
                dispatch(failure(error));

            }
        );
    };
};

const updateAccount = (formData) => {
    const success = (account) => {
        return {
            type: accountConstants.UPDATE_ACCOUNT_SUCCESS,
            payload: account
        };
    };

    const failure = ({error}) => {
        return {
            type: accountConstants.UPDATE_ACCOUNT_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: accountConstants.UPDATE_ACCOUNT_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());

        accountService.updateAccount(
            formData
        )
        .then(
            (account) => {
                dispatch(success(account.data));
            },
            (error) => {
                dispatch(failure(error));

            }
        );
    };
};

export const accountActions = {
    addAccount,
    updateAccount,
    getAccount
};

