import React, { Component } from 'react';
import { connect } from 'react-redux';
import { exportImportActions } from './../../actions';
import { API } from './../../helpers';
import { utility } from './../../helpers';

class ImportFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvfile: undefined,
            fileValidation: '',
            importProgress: false,
            importProgressMessage: '',
            uploadProgress: 0,
            uploadProgressShow: false,
            errorMessage: '',
            errorData: ''
        };
        this.handleClickUpload = this.handleClickUpload.bind(this);
    }
    componentDidMount(){
        this.props.dispatch(exportImportActions.getProductImportStatus());
        this.interval = setInterval(() => {
            this.props.dispatch(exportImportActions.getProductImportStatus());
        }, 10000);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.productImportStatus !== this.props.productImportStatus) {
            if (!utility.isObjectEmpty(nextProps.productImportStatus)) {
                if (nextProps.productImportStatus.message === 'Import is completed'
                    || nextProps.productImportStatus.message === 'Unable to get import status'
                ) {
                    this.setState({
                        importProgress: false,
                        importProgressMessage: ''
                    });
                } else {
                    this.setState({
                        importProgress: true,
                        importProgressMessage: 'Import Process is running, please wait...'
                    });
                }
            }
        }
    }        
    componentWillUnmount() {                   
        clearInterval(this.interval)
    }
    handleFileChange = (event) => {
        this.setState({
            csvfile: event.target.files[0],
            fileValidation: ''
        });
    };     
    validateFile() {
        let fileExt = this.state.csvfile.name.split('.').pop();
        let error = '';
        if (fileExt !== 'csv') {
            error = 'Wrong file format, upload only csv format file';
        } else if (this.state.csvfile.name.split('.').shift().search(/[<>'\s+\"\/;`%]/)>0) {
            error = 'The filename should not contain special characters or spaces.  Please rename and try again.';
        } else if (this.state.csvfile.name.split('.').shift().length > 25) {
            error = 'The filename should not be greater than 25 characters.';
        }
        this.setState({ fileValidation: error });
        if (error !== '') {
            return false;
        }
        return true;
    }
    handleClickUpload() {
        if (!this.validateFile()) {
            return;
        }
        this.setState({ importProgress: true, uploadProgressShow: true });
        const data = new FormData();
        data.append('file', this.state.csvfile, this.state.csvfile.name);
        API.ENDPOINT.post('/api/v1/import/trigger', data , {
            onUploadProgress: ProgressEvent => {
                this.setState({
                    uploadProgress: (ProgressEvent.loaded / ProgressEvent.total) * 100
                });
            }
        })
        .then((response) => {
            this.setState({ uploadProgressShow: false });
            console.log(response)
        }).catch((err) => {
            console.log(err)
            this.setState({
                errorData: err
            })
        });
    }
    render() {
        return (
            <div className="import-wrapper"> 
                <h3 className="title">Import Products</h3>
                <div className="file-upload-field">
                    <input 
                        className="csv-input"
                        type="file"
                        accept=".csv"
                        ref={input => {
                            this.filesInput = input;
                        }}
                        name="file"
                        placeholder={null}
                        onChange={this.handleFileChange} 
                    />
                </div>
                <p className="error">{this.state.fileValidation}</p>
                <div className="import-btn-wrapper">
                    <button 
                        className={`section-button ${this.state.importProgress ? 'disabled' : ''}`} 
                        onClick={this.handleClickUpload}
                    >
                        Import
                    </button>
                </div>
                {
                    (this.state.uploadProgressShow) ?
                        <p className="progress-wrapper">Progress : {Math.round(this.state.uploadProgress, 2) } %</p>                
                        :
                        null
                }
                <p>{this.state.importProgressMessage}</p>
                <p className="error">{this.state.errorData.message}</p>
            </div>
        );
	}
}

const mapStateToProps = (state) => {
    const {
        productImportStatus,
        productImportStatusRequestLoading,
        productImportStatusError       
    } = state.exportImportReducers;
    return (
        {
            productImportStatus,
            productImportStatusRequestLoading,
            productImportStatusError            
        }
    );
};
export default connect(mapStateToProps)(ImportFile);