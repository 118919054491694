import { jobScheduleConstants } from '../constants';

const initialState = {
    jobSchedule: {},
    jobScheduleRequestLoading: false,
    jobScheduleRequestError: null,
    jobScheduleUpdate: {},
    jobScheduleUpdateRequestLoading: false,
    jobScheduleUpdateError: null,
    jobScheduleCreate: {},
    jobScheduleCreateRequestLoading: false,
    jobScheduleCreateError: null
};

export function jobScheduleReducer(state = initialState, action) {
    switch (action.type) {
        case jobScheduleConstants.JOB_SCHEDULE_REQUEST:
            return {
                ...state,
                jobScheduleRequestLoading: true,
                jobScheduleRequestError: null,
                jobSchedule: {}
            };
        case jobScheduleConstants.JOB_SCHEDULE_SUCCESS:
            return {
                ...state,
                jobScheduleRequestLoading: false,
                jobScheduleRequestError: null,
                jobSchedule: action.payload
            };
        case jobScheduleConstants.JOB_SCHEDULE_FAILURE:
            return {
                ...state,
                jobScheduleRequestLoading: false,
                jobScheduleRequestError: 'Failed to get job schedule',
                jobSchedule: {}
            };
        case jobScheduleConstants.JOB_SCHEDULE_UPDATE_REQUEST:
           return {
                ...state,
                jobScheduleUpdateRequestLoading: true,
                jobScheduleUpdateError: null,
                jobScheduleUpdate: {}
            };
        case jobScheduleConstants.JOB_SCHEDULE_UPDATE_SUCCESS:
            return {
                ...state,
                jobScheduleUpdateRequestLoading: false,
                jobScheduleUpdateError: null,
                jobScheduleUpdate: action.payload
            };
        case jobScheduleConstants.JOB_SCHEDULE_UPDATE_FAILURE:
            return {
                ...state,
                jobScheduleUpdateRequestLoading: false,
                jobScheduleUpdateError: 'Failed to update, Please try later',
                jobScheduleUpdate: {}
            };
        case jobScheduleConstants.JOB_SCHEDULE_CREATE_REQUEST:
            return {
                 ...state,
                 jobScheduleCreateRequestLoading: true,
                 jobScheduleCreateError: null,
                 jobScheduleCreate: {}
             };
         case jobScheduleConstants.JOB_SCHEDULE_CREATE_SUCCESS:
             return {
                 ...state,
                 jobScheduleCreateRequestLoading: false,
                 jobScheduleCreateError: null,
                 jobScheduleCreate: action.payload
             };
         case jobScheduleConstants.JOB_SCHEDULE_CREATE_FAILURE:
             return {
                 ...state,
                 jobScheduleCreateRequestLoading: false,
                 jobScheduleCreateError: 'Failed to create, Please try later',
                 jobScheduleCreate: {}
             };            
        default:
            return state;
    }
}