import React, { Component } from 'react';
import CronSettingModal from './../modal/cron_setting_modal';
import { utility } from './../../helpers';

class Stats extends Component {
    constructor(props) {
        super(props);
        this.handleClickShowDetail = this.handleClickShowDetail.bind(this);
        this.handleClickSync = this.handleClickSync.bind(this);
    }        
    handleClickShowDetail(type, e) {
        e.preventDefault();
        this.props.getStatsDetails(type);
    }
    handleClickSync(type, e) {
        e.preventDefault();
        this.props.handleClickSync(type);
    }    
    renderStats(data) {
        const { jobSchedule } = this.props;
        return data.map((row) => {
            let total = 0;
            if (row.sync) {
                total = parseInt(row.success.count);
                const schedule = _.find(jobSchedule, function(o) {
                    return o.JobName === row.schedule_name;
                });
                return (
                    <div className="service" key={row.name}>
                        <p className="info"><i className="fas fa-info"></i></p>
                        <div className="count-wrapper">
                            <h2 className="count">
                                {total}
                            </h2>
                            <button
                                className="section-button"
                                onClick={(e) => {
                                    this.handleClickSync(row.type, e);
                                }}                                
                            >
                                Sync Now
                            </button>
                        </div>                        
                        <h3 className="title">{row.name}</h3>
                        <div className="checkout-table">
                            <p className="col">File Sent: {row.success.count}</p>
                        </div>
                        <div className="checkout-table">
                            <p className="col">
                                Last Sent:
                                {utility.getDisplayDateFromTimestamp(row.last_modified)}
                            </p>
                        </div>                        
                        <div className="show detail-button-wrapper mar-t-20">
                            <span className="pull-left og-show-detail-btn">
                                <CronSettingModal 
                                    schedule={schedule}
                                    scheduleName={row.schedule_name}
                                />
                            </span>
                            <button
                                className="og-show-detail-btn pull-right"
                                onClick={(e) => {
                                    this.handleClickShowDetail(row.type, e);
                                }}
                            >
                            Show Detail
                            </button>
                        </div>
                    </div>                    
                );
            }
            total = parseInt(row.success.count) + parseInt(row.error.count);
            return (
                <div className="service" key={row.name}>
                    <p className="info"><i className="fas fa-info"></i></p>
                    <div className="count-wrapper">
                        <h2 className="count">
                            {total}
                        </h2>
                    </div>
                    <h3 className="title">{row.name}</h3>
                    <div className="checkout-table-title">
                        <p className="col">Count</p>
                        <p className="col">Percent</p>
                        <p className="col">Status</p>
                    </div>
                    <div className="checkout-table">
                        <p className="col">{row.success.count}</p>
                        <p className="col">{utility.roundToTwoDecimail(row.success.percentage)}%</p>
                        <p className="col">Success</p>
                    </div>
                    <div className="checkout-table">
                        <p className="col">{row.error.count}</p>
                        <p className="col">{utility.roundToTwoDecimail(row.error.percentage)}%</p>
                        <p className="col">Error</p>
                    </div>
                    <p className="show detail-button mar-t-20">
                        <button
                            className="og-show-detail-btn pull-right"
                            onClick={(e) => {
                                this.handleClickShowDetail(row.type, e);
                            }}
                        >
                            Show Detail
                        </button>
                    </p>
                </div>
            );
        });
    }
    render() {
        const { statsData } = this.props;
        return(
            <section className="analytics">
                {this.renderStats(statsData)}
            </section>            
        );
    }
}
export default Stats;