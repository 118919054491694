import { combineReducers } from 'redux';

import { analyticsReducer } from './analytics_reducers';
import { accountReducer } from './account_reducers';
import { jobScheduleReducer } from './job_schedule_reducers';
import { feedSyncReducers } from './feed_sync_reducers';
import { exportImportReducers } from './import_export_reducers';

const appReducer = combineReducers({
    analyticsReducer,
    accountReducer,
    jobScheduleReducer,
    feedSyncReducers,
    exportImportReducers
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;