import { jobScheduleConstants } from '../constants';
import { jobScheduleService } from '../services';

const getAllJobSchedule = () => {
    const success = (jobSchedule) => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_SUCCESS,
            payload: jobSchedule
        };
    };

    const failure = ({ error }) => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());
        jobScheduleService.getAllJobSchedule()
        .then(
            (jobSchedule) => {
                dispatch(success(jobSchedule.data.data));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };
};
const createJobSchedule  = (data) => {
    const success = (jobSchedule) => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_CREATE_SUCCESS,
            payload: jobSchedule
        };
    };

    const failure = ({ error }) => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_CREATE_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_CREATE_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());
        jobScheduleService.createJobSchedule(data)
            .then(
                (jobSchedule) => {
                    dispatch(success(jobSchedule.data));
                },
                (error) => {
                    dispatch(failure(error));
                }
            );
    };
};
const updateJobSchedule  = (id, data) => {
    const success = (jobSchedule) => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_UPDATE_SUCCESS,
            payload: jobSchedule
        };
    };

    const failure = ({ error }) => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_UPDATE_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: jobScheduleConstants.JOB_SCHEDULE_UPDATE_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());
        jobScheduleService.updateJobSchedule(
            id,
            data
        )
            .then(
                (jobSchedule) => {
                    dispatch(success(jobSchedule.data));
                },
                (error) => {
                    dispatch(failure(error));
                }
            );
    };
};
export const jobScheduleActions = {
    getAllJobSchedule,
    createJobSchedule,
    updateJobSchedule
};