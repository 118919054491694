import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { Base64 } from 'js-base64';

import Layout from './components/layout/layout';

const LayoutRoute = ({ component: Component, ...rest }) => {
    const { path, location, computedMatch } = rest;
    localStorage.setItem('selectedMenu', '');
    if (path !== undefined
        && path !== ''
        && path.indexOf('/app/load/') !== -1
    ) {
        const values = queryString.parse(location.search);
        localStorage.removeItem('signed-payload');
        if (values.signed_payload !== undefined) {
            localStorage.setItem('signed-payload', values.signed_payload);
        }
        localStorage.setItem('selectedMenu', 'analytics');
    }
    if (path !== undefined
        && path !== ''
        && path.indexOf('/app/install-complete/') !== -1
    ) {
        const urlParam = computedMatch.params;
        const values = queryString.parse(location.search);
        localStorage.removeItem('signed-payload');
        localStorage.setItem('storehash', urlParam.storehash);
        if (values.email !== undefined) {
            localStorage.setItem('email', values.email);
        }
        // localStorage.setItem('appId', urlParam.appId);
    }
    const signedPayload =  localStorage.getItem('signed-payload');
    const storeHash =  localStorage.getItem('storehash');
    if (signedPayload === null
        && storeHash === null
        && path !== '/not-authorized'
        && path.indexOf('/app/install-complete/') === -1
    ) {
        return <Redirect to="/not-authorized" />;
    }
    if (path !== undefined
        && path !== ''
        && path.indexOf('/landing') !== -1
    ) {
        return(
            <Route
                {...rest}
                render={(props) => { return <Component {...props} />;}}
            />
        );
    }
    let email = '';
    if (email === null || email === '') {
        if (localStorage.getItem('email') !== null) {
            email = localStorage.getItem('email');
        }
    }
    if (signedPayload !== null && (email === null || email === '')) {
        const res = signedPayload.substring(0, signedPayload.indexOf('.'));
        const decoded = JSON.parse(Base64.decode(res));
        email = decoded.user.email;
    }
    return(
        <Route
            {...rest}
            render={(props) => { return <Layout email={email}><Component {...props} /></Layout>;}}
        />
    );
};

export default LayoutRoute;