import React, { Component } from 'react';
import { connect } from 'react-redux';
import { exportImportActions } from './../../actions';
import { utility } from './../../helpers';

class ExportFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exportProcess: true,
            exportProcessMessage: '',
            exportFileAvaliable: false
        };
        this.handleClickProductExport = this.handleClickProductExport.bind(this);
        this.handleClickDownloadCsv = this.handleClickDownloadCsv.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(exportImportActions.getProductExportStatus());
        this.interval = setInterval(() => {
            this.props.dispatch(exportImportActions.getProductExportStatus());
        }, 10000);
    }
    componentWillReceiveProps(nextProps) {
	    console.log(nextProps.productExportStatus.message);
        if (nextProps.productExportStatus !== this.props.productExportStatus) {
            if (!utility.isObjectEmpty(nextProps.productExportStatus)) {
                if (nextProps.productExportStatus.message === 'File available for download'
                    || nextProps.productExportStatus.message === 'No export status available'
                ) {
                    this.setState({
                        exportProcess: false,
                        exportProcessMessage: '',
                        exportFileAvaliable: true
                    });
                } else if (nextProps.productExportStatus.message == 'Export failed'
                ) {
                    this.setState({
                        exportProcess: false,
                        exportProcessMessage: 'Export failed. Please try again or contact the Administrator for help.',
                    });
                } else if (nextProps.productExportStatus.message == 'Permission denied'
                ) {
                    this.setState({
                        exportProcess: false,
                        exportProcessMessage: 'Export failed due to inadequate permissions. Please contact the Administrator for help.',
                    });
                } else {
                    this.setState({
                        exportFileAvaliable: false,
                        exportProcessMessage: 'Export Process is running, please wait...'
                    });
                }
            }
        }
        if (nextProps.productDownload !== this.props.productDownload) {
            if (nextProps.productDownload instanceof Blob) {
                const url = window.URL.createObjectURL(new Blob([nextProps.productDownload]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }     
    }
    componentWillUnmount() {  
        clearInterval(this.interval);
    }
    handleClickDownloadCsv() {
        if (this.props.productDownloadRequestLoading !== true) {
            this.props.dispatch(exportImportActions.downloadProductsCsv());
        }
    }
    handleClickProductExport() {
        if (this.state.exportProcess) {
            return;
        }
        this.setState({
            exportProcess: true,
            exportProcessMessage: 'Export Process is running, please wait...'
        });
        this.props.dispatch(exportImportActions.exportProducts());
    }
    render() {
        return (
            <div className="export-wrapper" >
                <h3 className="title">Export Products</h3>
                <button
                    className={`section-button ${this.state.exportProcess ? 'disabled' : ''}`} 
                    onClick={this.handleClickProductExport} 
                >
                    Initiate Export
                </button>             
                <div>
                    <span>{this.state.exportProcessMessage}</span>
                </div>    
                <div className="wrapper">
                    {
                        (this.state.exportFileAvaliable) ?
                            <a className="export-btn" onClick={this.handleClickDownloadCsv} >
                                Download csv File
                            </a>
                            :
                            null
                    }
                </div>
                <div>
                    {
                        (this.props.productDownloadStatusError) ?
                            <span>Export file not available to download</span>
                            :
                            null
                    }
                </div>
            </div>
        );
    }     
}

const mapStateToProps = (state) => {
    const {
        productExport,
        productExportRequestLoading,
        productExportError,
        productExportStatus,
        productExportStatusRequestLoading,
        productExportStatusError,
        productDownload,
        productDownloadRequestLoading,
        productDownloadStatusError        
    } = state.exportImportReducers;
    return (
        {
            productExport,
            productExportRequestLoading,
            productExportError,
            productExportStatus,
            productExportStatusRequestLoading,
            productExportStatusError,
            productDownload,
            productDownloadRequestLoading,
            productDownloadStatusError            
        }
    );
};
export default connect(mapStateToProps)(ExportFile);
