import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AccountForm from '../components/form/account_form';
import AlertMessageModal from '../components/modal/alert_message_modal';
import { accountActions } from './../actions';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: {},
            storeHash: props.match.params.storehash,
            createAccountResponse: false,
            createAccountResponseMessage: '',
            updateAccountResponse: false,
            updateAccountResponseMessage: '',
            analyticsPage: false,
            accountError: ''
        };
        this.addAccount = this.addAccount.bind(this);
        this.editAccount = this.editAccount.bind(this);
    }    
    componentDidMount() {
        this.props.dispatch(accountActions.getAccount());
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.createAccount !== this.props.createAccount) {
            if (nextProps.createAccount !== undefined
                && nextProps.createAccount.message !== ''
            ) {
                this.setState({
                    createAccountResponse: true,
                    createAccountResponseMessage: nextProps.createAccount.message
                });
            }
        }
        if (nextProps.updateAccount !== this.props.updateAccount) {
            if (nextProps.updateAccount !== undefined
                && nextProps.updateAccount.message !== ''
            ) {
                this.setState({
                    updateAccountResponse: true,
                    updateAccountResponseMessage: nextProps.updateAccount.message
                });
            }
        }
    }
    addAccount(accountData) {
        if (this.props.createAccountRequestLoading === false) {
            if (accountData.StoreHash !== '') {
                this.props.dispatch(accountActions.addAccount(accountData));
            } else {
                this.setState({ accountError: 'Cannot create account for missing store hash'})
            }
        }
    }
    editAccount(accountData) {
        if (this.props.updateAccountRequestLoading === false) {
            this.props.dispatch(accountActions.updateAccount(accountData));
        }        
    }
    accountCreateSuccess = () => {
        this.setState({
            createAccountResponse: false
        }, () => {
            this.setState({
                analyticsPage: true
            });
        });
    };
    accountUpdateSuccess = () => {
        this.setState({
            updateAccountResponse: false
        });
    };
    render() {
        if (this.state.analyticsPage) {
            return <Redirect to="/analytics" />;
        }
        return (
            <div className="section-wrapper">
                <h3>Account Configurations</h3>
                <AccountForm
                    storeHash={this.state.storeHash}
                    account={this.props.account}
                    addAccount={this.addAccount}
                    editAccount={this.editAccount}
                    saveLoading={this.props.createAccountRequestLoading}
                    updateLoading={this.props.createAccountRequestLoading}
                    createAccountResponse={this.state.createAccountResponse}
                    updateAccountResponse={this.state.updateAccountResponse}
                />
                <div>{this.state.accountError}</div>
                {
                    (!this.props.createAccountRequestLoading) ?
                        <AlertMessageModal
                            isOpen={this.state.createAccountResponse}
                            content={this.state.createAccountResponseMessage}
                            closeAlertMessage={this.accountCreateSuccess}
                        />
                        :
                        null
                }
                {
                    (!this.props.updateAccountRequestLoading) ?
                        <AlertMessageModal
                            isOpen={this.state.updateAccountResponse}
                            content={this.state.updateAccountResponseMessage}
                            closeAlertMessage={this.accountUpdateSuccess}
                        />
                        :
                        null
                }                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        createAccount,
        createAccountRequestLoading,
        createAccountError,
        account,
        getAccountRequestLoading,
        getAccountError,
        updateAccountRequestLoading,
        updateAccountError,
        updateAccount
    } = state.accountReducer;
    return (
        {
            createAccount,
            createAccountRequestLoading,
            createAccountError,
            account,
            getAccountRequestLoading,
            getAccountError,
            updateAccountRequestLoading,
            updateAccountError,
            updateAccount
        }
    );
};

export default connect(mapStateToProps)(Account);