import React from 'react';
import LayoutHeader from './layout_header';

const Layout = ({ children, ...rest }) => {
    return (
        <div className="analytics-wrapper">
            <LayoutHeader {...rest} />
            <div className="page-container">
                {children}
            </div>
        </div>
    );
};

export default Layout;