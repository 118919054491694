import { exportImportConstants } from '../constants';
import { exportImportService } from '../services';

const exportProducts = () => {
    const success = (products) => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_SUCCESS,
            payload: products
        };
    };

    const failure = ({error}) => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());

        exportImportService.exportProducts()
        .then(
            (products) => {
                dispatch(success(products.data));
            },
            (error) => {
                dispatch(failure(error));

            }
        );
    };
};

const getProductExportStatus = () => {
    const success = (status) => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_STATUS_SUCCESS,
            payload: status
        };
    };

    const failure = ({error}) => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_STATUS_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_STATUS_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());

        exportImportService.getProductExportStatus()
        .then(
            (status) => {
                dispatch(success(status.data));
            },
            (error) => {
                dispatch(failure(error));

            }
        );
    };
};

const downloadProductsCsv = () => {
    const success = (download) => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_DOWNLOAD_SUCCESS,
            payload: download
        };
    };

    const failure = ({error}) => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_DOWNLOAD_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: exportImportConstants.EXPORT_PRODUCT_DOWNLOAD_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());

        exportImportService.downloadProductsCsv()
        .then(
            (download) => {
                dispatch(success(download.data));
            },
            (error) => {
                dispatch(failure(error));

            }
        );
    };
};

const getProductImportStatus = () => {
    const success = (status) => {
        return {
            type: exportImportConstants.IMPORT_PRODUCT_STATUS_SUCCESS,
            payload: status
        };
    };

    const failure = ({error}) => {
        return {
            type: exportImportConstants.IMPORT_PRODUCT_STATUS_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: exportImportConstants.IMPORT_PRODUCT_STATUS_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());
        exportImportService.getProductImportStatus()
        .then(
            (status) => {
                dispatch(success(status.data));
            },
            (error) => {
                dispatch(failure(error));

            }
        );
    };
};

export const exportImportActions = {
    exportProducts,
    getProductExportStatus,
    downloadProductsCsv,
    getProductImportStatus
};

