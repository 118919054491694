import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom";
import Logo from '../../assets/costrategix-logo.jpg';

import {
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

class LayoutHeader extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }
    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
        const active = localStorage.getItem('selectedMenu');
        return (
            <div>
                <Navbar color="light" light expand="md" className="nc-navbar">
                    <NavbarBrand href="/">
                        <img src={Logo} className="logo" alt="" />
                    </NavbarBrand>
                    <div className="menu-wrapper">
                        <NavLink className={`menu-item ${ (active !== '' && active !== null) ? 'active' : ''}`} to="/analytics" >
                            <div className="menu-icon"><i className="fas fa-chart-bar"></i></div>
                            <div className="menu-label">Analytics</div>                        
                        </NavLink>
                        <NavLink className="menu-item" to="/export-import">
                            <div className="menu-icon"><i className="fas fa-chart-bar"></i></div>
                            <div className="menu-label">Export / Import</div>
                        </NavLink>
                    </div> 
                    <div className="username-wrapper">
                        {
                            (this.props.email !== null) ? this.props.email : null
                        }
                    </div>   
                    <NavLink to="/account" className="drop-down-menu">
                        <i className="fas fa-cog"></i>
                    </NavLink>                 
                </Navbar>
            </div>
        );
    }
}
export default LayoutHeader;