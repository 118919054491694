import React, { Component } from 'react';
import { utility } from './../../helpers'; 
import _ from 'lodash'; 
import {FormGroup, Label, Input} from 'reactstrap';

class AccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MerchantId: '',
            ClientSecret: '',
            OGApiEndpoint: '',
            OGSubscriptionApiEndpoint: '',
            SftpHost: '',
            SftpPort: '',
            SftpUser: '',
            SftpKey: '',
            SftpDocumentLocation: '',
            errors: {},
            accountEdit: false,
            ConfigType: '',
            IncentiveConfigType: '',
            OfferId: '',
            isConfigTypeAvailable: false,
            FreeShippingCouponEnabled: 0,
            DeleteShippingCoupon: 1,
            FreeShippingCouponType: 'DYNAMIC',
            Coupon: '',
            Prefix: '',  
            updatingClientSecret: false,
            updatingSftpKey: false,
            PurchasingDisabled: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.configTypeChanged = this.configTypeChanged.bind(this);
        this.incentiveConfigTypeChanged = this.incentiveConfigTypeChanged.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.account !== this.props.account) {
            if (!utility.isObjectEmpty(nextProps.account)) {
                const { account } = nextProps;
                let Coupon = '', Prefix = '', 
                   freeshippingConfig = {type: '', configuration: {}}, FreeShippingCouponType = ''; 
                if (account.FreeShippingConfig) {
                    freeshippingConfig = JSON.parse(account.FreeShippingConfig);
                    Prefix = freeshippingConfig.configuration ? freeshippingConfig.configuration.prefix : '';
                    FreeShippingCouponType = 'DYNAMIC';
                }
                this.setState({
                    MerchantId: account.MerchantId,
                    OGApiEndpoint: account.OGApiEndpoint,
                    OGSubscriptionApiEndpoint: account.OGSubscriptionApiEndpoint,
                    ClientSecret: account.ClientSecret,
                    SftpKey: account.SftpKey,
                    SftpHost: account.SftpHost,
                    SftpPort: account.SftpPort,
                    SftpUser: account.SftpUser,
                    SftpDocumentLocation: account.SftpDocumentLocation,
                    accountEdit: true,
                    ConfigType: account.ConfigType || '',
                    IncentiveConfigType: account.IncentiveConfigType || '',
                    OfferId: account.OfferId || '',
                    isConfigTypeAvailable: account.ConfigType !== '' ? true : false,
                    DeleteShippingCoupon: account.DeleteShippingCoupon == '1' || account.DeleteShippingCoupon == 1 ? 1 : 0,
                    FreeShippingCouponEnabled: account.FreeShippingCouponEnabled == '1' || account.FreeShippingCouponEnabled == 1 ? 1 : 0,
                    Coupon,
                    Prefix,
                    FreeShippingCouponType,
                    PurchasingDisabled: account.PurchasingDisabled == '1' || account.PurchasingDisabled == 1 ? 1 : 0
                });
            }
        }
        if (nextProps.createAccountResponse !== this.props.createAccountResponse) {
            if (nextProps.createAccountResponse) {
                if (this.state.ConfigType !== '') {
                    this.setState({
                        isConfigTypeAvailable: true
                    });
                }
            }
        }
        if (nextProps.updateAccountResponse !== this.props.updateAccountResponse) {
            if (nextProps.updateAccountResponse) {
                if (this.state.ConfigType !== '') {
                    this.setState({
                        isConfigTypeAvailable: true
                    });
                }
            }
        }
    }

    handleFocus(e){
        if(e.target.name == 'ClientSecret'){
            this.setState({
                updatingClientSecret : true
            });
        }

        if(e.target.name == 'SftpKey'){
            this.setState({
                updatingSftpKey : true
            });
        }
    }

    handleBlur(e){
        if(e.target.name == 'ClientSecret'){
            this.setState({
                updatingClientSecret : false
            });
        }

        if(e.target.name == 'SftpKey'){
            this.setState({
                updatingSftpKey : false
            });
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name] : e.target.value,
            errors: {}
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let FreeShippingConfig = {};
        const {FreeShippingCouponType, Coupon, Prefix} = this.state;
        FreeShippingConfig = {type: "DYNAMIC", configuration: {prefix: Prefix}};

        const encodedClientSecret = new Buffer(this.state.ClientSecret).toString('base64');
        const encodedSftpKey = new Buffer(this.state.SftpKey).toString('base64');

        const formData = {
            MerchantId: this.state.MerchantId,
            OGApiEndpoint: this.state.OGApiEndpoint,
            OGSubscriptionApiEndpoint: this.state.OGSubscriptionApiEndpoint,
            ClientSecret: encodedClientSecret,
            SftpHost: this.state.SftpHost,
            SftpPort: this.state.SftpPort,
            SftpUser: this.state.SftpUser,
            SftpKey: encodedSftpKey,
            SftpDocumentLocation: this.state.SftpDocumentLocation,
            ConfigType: this.state.ConfigType,
            IncentiveConfigType: this.state.IncentiveConfigType,
            OfferId: this.state.OfferId,
            DeleteShippingCoupon: this.state.DeleteShippingCoupon,
            FreeShippingCouponEnabled: this.state.FreeShippingCouponEnabled,
            FreeShippingConfig,
            PurchasingDisabled: this.state.PurchasingDisabled         
        };
        console.log(formData, 'formdata');
        if (this.handleValidation()) {
            if (this.state.accountEdit) {
                this.props.editAccount(formData);
            } else {
                formData.StoreHash = this.props.storeHash;
                this.props.addAccount(formData);
            }
            // this.setState({
            //     formData : {
            //         MerchantId: '',
            //         ClientSecret: '',
            //         SftpHost: '',
            //         SftpPort: '',
            //         SftpUser: '',
            //         SftpKey: '',
            //         SftpDocumentLocation: ''
            //     }
            // });
        }
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        const fields = [
            { 'MerchantId': { 'message': 'OrderGroove Merchant ID cannot be empty'} },
            { 'ClientSecret': { 'message': 'OrderGroove Secret Key cannot be empty'} },
            { 'OGApiEndpoint': { 'message': 'OrderGroove Api Endpoint cannot be empty'} },
            { 'OGSubscriptionApiEndpoint': { 'message': 'OrderGroove Subscription Api Endpoint cannot be empty'} },
            { 'SftpHost': { 'message': 'Server Address cannot be empty'} },
            { 'SftpPort': { 'message': 'Server Port cannot be empty'} },
            { 'SftpUser': { 'message': 'Server user name cannot be empty'} },
            { 'SftpKey': { 'message': 'Server password cannot be empty'} }
        ];
        _.forEach(this.state, function(value, key) {
            const fieldFound = _.findIndex(fields, key);
            if (fieldFound !== -1) {
                if (value === '' || value.trim() === '' || value.length === 0) {
                    formIsValid = false;
                    errors[key] = fields[fieldFound][key]['message'];
                }
            }
        });
        this.setState({ errors: errors });

        return formIsValid;
    }

    configTypeChanged = (e, propertyName) => {
        this.setState({
            ConfigType: e.target.value,
            IncentiveConfigType: ''
        });
    }

    incentiveConfigTypeChanged = (e, propertyName) => {
        this.setState({
            ['IncentiveConfigType']: e.target.value
        });
    }

    freeshippingCouponTypeChanged = (e) => {
        this.setState({
            FreeShippingCouponType: e.target.value,
            DeleteShippingCoupon: e.target.value === 'STATIC' ? 0 : this.state.DeleteShippingCoupon
        });
    }

    renderBCProductConfiguration = () => {
        const { IncentiveConfigType, OfferId } = this.state;
        return (
            <div>
                    <FormGroup inline check onChange={(e) => { this.incentiveConfigTypeChanged(e)}}>
                        <Label>
                            <Input type="radio" value="productLevelIncentives" name="Product Level Incentives"
                                checked = {IncentiveConfigType === 'productLevelIncentives'}
                            />
                                Product Level Incentives
                        </Label>
                    </FormGroup>
                    <FormGroup inline check onChange={(e) => { this.incentiveConfigTypeChanged(e)}}>
                            <Label>
                                <Input type="radio" value="incentiveGroups" name="Incentive Groups"
                                checked = {IncentiveConfigType === 'incentiveGroups'}
                                />
                                Incentive Groups
                            </Label>
                    </FormGroup>
                    {IncentiveConfigType === 'incentiveGroups' ? 
                    <div className="form-group">
                        <label>Global Offer ID</label>
                        <Input 
                            className="form-control"
                            type="text"
                            value={OfferId}
                            name="Global Offer ID"
                            onChange = {(e) => {this.setState({OfferId: e.target.value})}}
                        />
                        <p className="error"></p>
                    </div> : null}
            </div>
        );
    }

    renderFreeShippingCouponTextComponent = () => {
        const {FreeShippingCouponType, Coupon, Prefix} = this.state;
        let component = null;
        return (
            FreeShippingCouponType == 'STATIC' ? 
                    <div className="form-group">
                        <Input 
                            className="form-control"
                            type="text"
                            value={Coupon}
                            name="Static coupon"
                            onChange = {(e) => {this.setState({Coupon: e.target.value})}}
                        />
                        <p className="error"></p>
                    </div> : 
                    <div>
                        <FormGroup>
                            <Input 
                                className="form-control"
                                type="text"
                                value={Prefix}
                                name="Dynamic coupon text"
                                onChange = {(e) => {this.setState({Prefix: e.target.value})}}
                            />
                        </FormGroup>
                        <p className="error"></p>
                    </div>
        );
    }

    handleCheckbox = (e, type) => {
        this.setState({
            [type]: e && e.target.checked ? 1 : 0
        });
    }

    render() {
        const { ConfigType, isConfigTypeAvailable, FreeShippingCouponEnabled, 
                DeleteShippingCoupon, FreeShippingCouponType, PurchasingDisabled } = this.state;

        var clientSecretValue = '';
        var sftpKeyValue = '';
        if(this.state.ClientSecret){
            if(this.state.updatingClientSecret){
                clientSecretValue = this.state.ClientSecret;
            } else{
                for(var i=0; i< this.state.ClientSecret.length; i++){
                    clientSecretValue = clientSecretValue + '*';
                }
            }
        }

        if(this.state.SftpKey){
            if(this.state.updatingSftpKey ){
                sftpKeyValue = this.state.SftpKey;
            } else{
                for(var i=0; i< this.state.SftpKey.length; i++){
                    sftpKeyValue = sftpKeyValue + '*';
                }
            }
        }
        return (
            <div>
                <p>For connecting to OrderGroove account we need below details.</p>
                <h5 className="title">Your OrderGroove API Credentials</h5>
                <form className="og-account-wrapper" onSubmit={this.handleSubmit}> 
                    <div className="form-group">
                        <label>OrderGroove Merchant ID</label>
                        <input
                            className="form-control"
                            type="text"
                            name="MerchantId"
                            value={this.state.MerchantId || ''} 
                            onChange={this.handleChange}
                        />
                        <p className="error">{this.state.errors["MerchantId"]}</p>
                    </div>
                    <div  className="form-group">
                        <label>OrderGroove Secret Key</label>
                        <input
                            className="form-control"
                            type="password"
                            name="ClientSecret"
                            value={ clientSecretValue }
                            onChange={this.handleChange}
                            onFocus={this.handleFocus} 
                            onBlur={this.handleBlur}
                        />
                        <p className="error">{this.state.errors["ClientSecret"]}</p>
                    </div>

                    <h5 className="title mar-t-40">OrderGroove Endpoints</h5>
                    <div className="form-group">
                        <label>OrderGroove API Endpoint</label>
                        <input
                            className="form-control"
                            type="text"
                            name="OGApiEndpoint"
                            value={this.state.OGApiEndpoint || ''}
                            onChange={this.handleChange} 
                        />
                        <p className="error">{this.state.errors["OGApiEndpoint"]}</p>
                    </div>
                    <div className="form-group">
                        <label>OrderGroove Subscription API Endpoint</label>
                        <input
                            className="form-control"
                            type="text"
                            name="OGSubscriptionApiEndpoint"
                            value={this.state.OGSubscriptionApiEndpoint || ''} 
                            onChange={this.handleChange}
                        />
                        <p className="error">{this.state.errors["OGSubscriptionApiEndpoint"]}</p>
                    </div>

                    <h5 className="title mar-t-40">Your OrderGroove XML Feed Credentials</h5>
                    <div  className="form-group">
                        <label>Server Address</label>
                        <input
                            className="form-control"
                            type="text"
                            name="SftpHost"
                            value={this.state.SftpHost || ''}
                            onChange={this.handleChange} 
                        />
                        <p className="error">{this.state.errors["SftpHost"]}</p>
                    </div>
                    <div  className="form-group">
                        <label>Sftp Port</label>
                        <input
                            className="form-control"
                            type="number"
                            name="SftpPort"
                            value={this.state.SftpPort || ''} 
                            onChange={this.handleChange}
                        />
                        <p className="error">{this.state.errors["SftpPort"]}</p>
                    </div>
                    <div  className="form-group">
                        <label>Server UserName</label>
                        <input
                            className="form-control"
                            type="text"
                            name="SftpUser"
                            value={this.state.SftpUser || ''}
                            onChange={this.handleChange}
                        />
                        <p className="error">{this.state.errors["SftpUser"]}</p>
                    </div>
                    <div  className="form-group">
                        <label>Server Password</label>
                        <input
                            className="form-control"
                            type="password"
                            name="SftpKey"
                            value={ sftpKeyValue }
                            onChange={this.handleChange}
                            onFocus={this.handleFocus} 
                            onBlur={this.handleBlur}
                        />
                        <p className="error">{this.state.errors["SftpKey"]}</p>
                    </div>                     
                    <div  className="form-group">
                        <label>Feed File Location</label>
                        <input
                            className="form-control"
                            type="textarea"
                            name="SftpDocumentLocation"
                            value={this.state.SftpDocumentLocation || ''}
                            onChange={this.handleChange}
                            placeholder="The feed file location(leave this empty to use the default location)" />
                    </div>
                    <h5 className="title mar-t-40">Subscription Owned By</h5>
                    <div>
                        <FormGroup inline check onChange={(e) => { this.configTypeChanged(e)}}>
                            <Label>
                                <Input
                                    type="radio"
                                    value="bc" name="bc"
                                    checked = {ConfigType === 'bc'}
                                    disabled = {isConfigTypeAvailable}
                                />
                                BC
                            </Label>
                        </FormGroup>
                        <FormGroup  inline check onChange={(e) => { this.configTypeChanged(e)}}>
                            <Label>
                                <Input
                                    type="radio"
                                    value="og"
                                    name="og"
                                    checked = {ConfigType === 'og'}
                                    disabled = {isConfigTypeAvailable}
                                />
                                OG
                            </Label>
                        </FormGroup>
                    </div>
                    { 
                        ConfigType === 'bc' && this.renderBCProductConfiguration()
                    }
                    <h5 className="title mar-t-40">Free Shipping Coupon</h5>
                    <div className="mar-b-20">
                        <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" id="freeshippingCoupon" 
                                     checked = {FreeShippingCouponEnabled ? true : false}
                                     defaultChecked = {FreeShippingCouponEnabled ? true : false} 
                                     onChange = {(e) => {this.handleCheckbox(e, 'FreeShippingCouponEnabled')}}/>{' '}
                                    Free Shipping Coupon
                                </Label>
                        </FormGroup>
                     </div>
                     { FreeShippingCouponEnabled ? 
                        <div className="mar-b-20">
                            {FreeShippingCouponType != '' && this.renderFreeShippingCouponTextComponent()}
                        </div>
                     : null
                    }
                    <div className="mar-b-20">
                        <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" id="freeshippingCoupon" 
                                     checked = {DeleteShippingCoupon ? true : false}
                                     defaultChecked = {DeleteShippingCoupon ? true : false} 
                                     onChange = {(e) => {this.handleCheckbox(e, 'DeleteShippingCoupon')}}
                                     disabled = { FreeShippingCouponType === 'STATIC' ? true : false }/>{' '}
                                    Delete coupons after use
                                </Label>    
                        </FormGroup>    
                     </div> 
                    <h5 className="title mar-t-40">Product Feed</h5>    
                    <div className="mar-b-20">  
                        <FormGroup check>   
                                <Label check>   
                                    <Input type="checkbox" id="pPurchasingDisabled"     
                                     checked = {PurchasingDisabled ? true : false}  
                                     defaultChecked = {PurchasingDisabled ? true : false}   
                                     onChange = {(e) => {this.handleCheckbox(e, 'PurchasingDisabled')}} 
                                     value="true"/>{' '}    
                                    Exclude Products that are marked not purchasable on the store
                                </Label>
                        </FormGroup>
                     </div>
                     
                    <div  className="form-group">
                        {
                            (this.props.saveLoading || this.props.updateLoading) ?
                                <div>Saving...</div>
                                :
                                <input className="section-button" type="submit" value="Save" />
                        }
                    </div>    
                </form>
            </div>
        )
    } 
};

export default AccountForm;
