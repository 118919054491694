import React, { Component } from 'react';
import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

class DateSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: new Date(),
            toDate: new Date()
        };
        this.handleClickUpdateStats = this.handleClickUpdateStats.bind(this);
        this.handleChangeFromDate = this.handleChangeFromDate.bind(this);
        this.handleChangeToDate = this.handleChangeToDate.bind(this);
        this.handleClickMonth = this.handleClickMonth.bind(this);
        this.handleClickWeek = this.handleClickWeek.bind(this);
        this.handleClickDay = this.handleClickDay.bind(this);
    }
    handleClickUpdateStats(e) {
        e.preventDefault();
        this.props.handleUpdateDate(this.state.fromDate, this.state.toDate);
    }    
    handleChangeFromDate(date) {
        this.setState({
            fromDate: date
        });
    }
    handleChangeToDate(date) {
        this.setState({
            toDate: date
        });
    }
    handleClickMonth() {
        this.setState({
            fromDate: moment().subtract(1, 'months').toDate(),
            toDate: moment().toDate()
        }, () => {
            this.props.handleUpdateDate(this.state.fromDate, this.state.toDate);
        });
    }
    handleClickWeek() {
        this.setState({
            fromDate: moment().subtract(7, 'days').toDate(),
            toDate: moment().toDate()
        }, () => {
            this.props.handleUpdateDate(this.state.fromDate, this.state.toDate);
        });
    }
    handleClickDay() {
        this.setState({
            fromDate: moment().subtract(1, 'days').toDate(),
            toDate: moment().toDate()
        }, () => {
            this.props.handleUpdateDate(this.state.fromDate, this.state.toDate);
        });
    }
    render() {
        const { statsData } = this.props;
        return(
            <div className="search-header">
                <Button onClick={this.handleClickMonth} color="primary">Month</Button>
                <Button onClick={this.handleClickWeek} color="primary">Week</Button>
                <Button onClick={this.handleClickDay} color="primary">Day</Button> 
                <div className="date-picker-wrapper"> 
                    <DatePicker selected={this.state.fromDate} onChange={this.handleChangeFromDate} /> 
                    <span className="dates-arrow"> 
                        <i className="fas fa-arrow-right"></i>
                    </span> 
                    <DatePicker selected={this.state.toDate} onChange={this.handleChangeToDate} />
                </div> 
                <Button onClick={this.handleClickUpdateStats} color="primary">Update Stats</Button> 
            </div>
        );
    }
}

export default DateSearch;
