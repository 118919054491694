import { analyticsConstants } from '../constants';

const initialState = {
    stats: {},
    statsRequestLoading: false,
    statsRequestError: null,
    statsDetail: {},
    statsDetailRequestLoading: false,
    statsDetailRequestError: null,
};

export function analyticsReducer(state = initialState, action) {
    switch (action.type) {
        case analyticsConstants.STATS_REQUEST:
            return {
                ...state,
                statsRequestLoading: true,
                statsRequestError: null,
                stats: {}
            };
        case analyticsConstants.STATS_SUCCESS:
            return {
                ...state,
                statsRequestLoading: false,
                statsRequestError: null,
                stats: action.payload
            };
        case analyticsConstants.STATS_FAILURE:
            return {
                ...state,
                statsRequestLoading: false,
                statsRequestError: 'Failed to load analytics',
                stats: {}
            };
        case analyticsConstants.STATS_DETAIL_REQUEST:
           return {
                ...state,
                statsDetailRequestLoading: true,
                statsDetailRequestError: null,
                statsDetail: {}
            };
        case analyticsConstants.STATS_DETAIL_SUCCESS:
            return {
                ...state,
                statsDetailRequestLoading: false,
                statsDetailRequestError: null,
                statsDetail: action.payload
            };
        case analyticsConstants.STATS_DETAIL_FAILURE:
            return {
                ...state,
                statsDetailRequestLoading: false,
                statsDetailRequestError: 'Failed to load detail, Please try later',
                statsDetail: {}
            };
        default:
            return state;
    }
}