import { feedSyncConstants } from '../constants';

const initialState = {
    syncProduct: {},
    syncProductLoading: false,
    syncProductError: null,
    syncProductCategory: {},
    syncProductCategoryLoading: false,
    syncProductCategoryError: null
};

export function feedSyncReducers(state = initialState, action) {
    switch (action.type) {
        case feedSyncConstants.PRODUCT_SYNC_REQUEST:
            return {
                ...state,
                syncProductLoading: true,
                syncProductError: null,
                syncProduct: {}
            };
        case feedSyncConstants.PRODUCT_SYNC_SUCCESS:
            return {
                ...state,
                syncProductLoading: false,
                syncProductError: null,
                syncProduct: action.payload
            };
        case feedSyncConstants.PRODUCT_SYNC_FAILURE:
            return {
                ...state,
                syncProductLoading: false,
                syncProductError: "Failed to sync products",
                syncProduct: {}
            };
        case feedSyncConstants.PRODUCT_CATEGORY_SYNC_REQUEST:
            return {
                ...state,
                syncProductCategoryLoading: true,
                syncProductCategoryError: null,
                syncProductCategory: {}
            };
        case feedSyncConstants.PRODUCT_CATEGORY_SYNC_SUCCESS:
            return {
                ...state,
                syncProductCategoryLoading: false,
                syncProductCategoryError: null,
                syncProductCategory: action.payload
            };
        case feedSyncConstants.PRODUCT_CATEGORY_SYNC_FAILURE:
            return {
                ...state,
                syncProductCategoryLoading: false,
                syncProductCategoryError: "Failed to sync products category",
                syncProductCategory: {}
            };            
        default:
            return state;
    }
}