import { feedSyncConstants } from '../constants';
import { feedSyncService } from '../services';

const syncProductFeed = () => {
    const success = (productSync) => {
        return {
            type: feedSyncConstants.PRODUCT_SYNC_SUCCESS,
            payload: productSync
        };
    };

    const failure = ({ error }) => {
        return {
            type: feedSyncConstants.PRODUCT_SYNC_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: feedSyncConstants.PRODUCT_SYNC_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());
        feedSyncService.productFeedSync()
        .then(
            (productSync) => {
                dispatch(success(productSync.data));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };
};

const syncProductCategoryFeed = () => {
    const success = (productCategorySync) => {
        return {
            type: feedSyncConstants.PRODUCT_CATEGORY_SYNC_SUCCESS,
            payload: productCategorySync
        };
    };

    const failure = ({ error }) => {
        return {
            type: feedSyncConstants.PRODUCT_CATEGORY_SYNC_FAILURE,
            error
        };
    };

    const request = () => {
        return {
            type: feedSyncConstants.PRODUCT_CATEGORY_SYNC_REQUEST
        };
    };
    return (dispatch) => {
        dispatch(request());
        feedSyncService.productCategoryFeedSync()
        .then(
            (productCategorySync) => {
                dispatch(success(productCategorySync.data));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };
};

export const feedSyncActions = {
    syncProductFeed,
    syncProductCategoryFeed
};