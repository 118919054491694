import { accountConstants } from '../constants/account_constant';

const initialState = {
    createAccount: {},
    createAccountRequestLoading: false,
    createAccountError: null,
    account: {},
    getAccountRequestLoading: false,
    getAccountError: null,
    updateAccount: {},
    updateAccountRequestLoading: false,
    updateAccountError: null
};

export function accountReducer(state = initialState, action) {
    switch(action.type) {
        case accountConstants.ADD_ACCOUNT_SUCCESS:
            return {
                ...state,
                createAccountRequestLoading: false,
                createAccountError: null,
                createAccount: action.payload
            };
        case accountConstants.ADD_ACCOUNT_REQUEST:
            return {
                ...state,
                createAccountRequestLoading: true,
                createAccountError: null,
                createAccount: {}
            };
        case accountConstants.ADD_ACCOUNT_FAILURE:
            return {
                ...state,
                createAccountRequestLoading: false,
                createAccountError: 'Failed to create account',
                createAccount: {}
            };
        case accountConstants.GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                getAccountRequestLoading: false,
                getAccountError: null,
                account: action.payload
            };
        case accountConstants.GET_ACCOUNT_REQUEST:
            return {
                ...state,
                getAccountRequestLoading: true,
                getAccountError: null,
                account: {}
            };
        case accountConstants.GET_ACCOUNT_FAILURE:
            return {
                ...state,
                getAccountRequestLoading: false,
                getAccountError: 'Failed to get account details',
                account: {}
            };  
        case accountConstants.UPDATE_ACCOUNT_REQUEST:
            return {
                ...state,
                updateAccountRequestLoading: true,
                updateAccountError: null,
                updateAccount: {}
            };
        case accountConstants.UPDATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                updateAccountRequestLoading: false,
                updateAccountError: null,
                updateAccount: action.payload
            };
        case accountConstants.UPDATE_ACCOUNT_FAILURE:
            return {
                ...state,
                updateAccountRequestLoading: false,
                updateAccountError: 'Failed to update account',
                updateAccount: {}
            };    
        default:
            return state;
    }
};